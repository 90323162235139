import {pdfjs, Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './Sample.css';


pdfjs.GlobalWorkerOptions.workerSrc =
    window.location.origin + "/pdf.worker.min.mjs";
'use client';

export type FloatListStringTuple = [number[], string, string, string] | undefined;


import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import React, {useEffect, useMemo, useRef, useState} from 'react';
import FileUploadBox from "./fileUpload";


const removed = ["BEGIN_TABLE", "==TABLE========="]


function get_tooltip_body(category: string, text: string | null): React.ReactNode {
    console.log(text);

    const filteredText = (category == "Image" || text == null) ? "": removed.reduce(
            (acc, item) => acc.replace(new RegExp(item, 'g'), ""),
            text
        );

    if (category === 'Table') {
        // Render filteredText as HTML
        return (
            <div className="tooltip-content"
                 dangerouslySetInnerHTML={{__html: filteredText}}
            />
        );
    } else {
        // Return the text wrapped in a paragraph
        return <p style={{ whiteSpace: 'pre-wrap' }}>{filteredText}</p>;
    }
}

type FileResult = {
    status: string;
    chunks: any[];
};

type ResponseData = {
    files: any
};

const getRandomColorWithOpacity = () => {
    const r = Math.floor(Math.random() * 256); // Red: 0-255
    const g = Math.floor(Math.random() * 256); // Green: 0-255
    const b = Math.floor(Math.random() * 256); // Blue: 0-255
    return `rgba(${r}, ${g}, ${b}, 0.3)`; // 0.3 opacity
};

//bboxes is a list of [[page_bbox, category, color],]

function transformData(data: any): FloatListStringTuple[] {
    const result: FloatListStringTuple[] = [];

    if (data) {

        data.files.forEach((file: any) => {
            for (const fileName in file) {
                if (file[fileName].chunks) {
                    file[fileName].chunks.forEach((chunk: any) => {
                        result.push([chunk.bboxes, chunk.box_category, getRandomColorWithOpacity(), chunk.text]);
                    });
                }
            }
        });

        return result;
    }
    return []

}


export default function Sample() {
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState<ResponseData | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [hoveredInfo, setHoveredInfo] = useState({category: null, text: null});
    const isPinned = useRef(false);

    const data = useMemo(() => transformData(responseData), [responseData]);


    return (
        <div className="Example">
            <header>
                <div className="header-container">
                    <img src="/TitanML-Logo-4440x1000.png" alt="Logo" className="logo"/>
                    <h1>Takeoff DPP</h1>
                </div>
            </header>
            <div
                className="Example__container"
                onClick={(e) => {
                    const target = e.target as Element;
                    if (target.tagName.toLowerCase() !== 'rect') {
                        isPinned.current = false;
                        setHoveredInfo({category: null, text: null});
                    }
                }}
            >

                <FileUploadBox
                    setResponseData={setResponseData}
                    setLoading={setIsLoading}
                    loading={isLoading}
                    setFile={setFile}
                    file={file}
                />

                <div className="main-content">
                    <div className="Example__document-viewer">
                        {!isLoading && file && responseData && (
                            <PdfViewer bboxes={data} fileBinary={file} setHoveredInfo={setHoveredInfo}
                                       isPinned={isPinned}/>
                        )}
                        {isLoading && <p>Loading</p>}
                    </div>

                    <div className="right-side-panel">
                        {hoveredInfo.category && (
                            <div className="info-panel">
                                <h3 className="info-title">{hoveredInfo.category}</h3>
                                {get_tooltip_body(hoveredInfo.category, hoveredInfo.text)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );


}


const renderRectangles = (bboxes_all: any[], pageNumber: number, frame_width: number, frame_height: number, isPinned: any, setHoveredInfo: (arg0: {
    category: any;
    text: any;
}) => void) => {
    const renderedRectangles = [];

    for (const [index, tuple] of bboxes_all.entries()) {
        if (tuple !== undefined) {
            const [chunk_bboxes, category, color, text] = tuple;
            const numRectangles = chunk_bboxes?.length / 5;
            for (let i = 0; i < numRectangles; i++) {
                const startIndex = i * 5;
                const [page, top_left_x, top_left_y, bbox_width, bbox_height] = chunk_bboxes.slice(startIndex, startIndex + 5);

                if (page !== pageNumber) {
                    continue;
                }

                const expansionFactorX = 1.02;
                const expansionFactorY = 1.05;
                const halfExpansionOffsetX = (expansionFactorX - 1) / 2;
                const halfExpansionOffsetY = (expansionFactorY - 1) / 2;


                if (top_left_y && top_left_x && bbox_height && bbox_width) {
                    // @ts-ignore
                    renderedRectangles.push(
                        <div
                            key={index * 100 + i}
                            className="rectangle-container"
                            tabIndex={0}
                            style={{
                                top: ((top_left_y * frame_height) - (bbox_height * frame_height * halfExpansionOffsetY)),
                                left: ((top_left_x * frame_width) - (bbox_width * frame_width * halfExpansionOffsetX)),
                                width: bbox_width * frame_width * expansionFactorX,
                                height: bbox_height * frame_height * expansionFactorY,
                                borderRadius: '3px',
                                backgroundColor: color,
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                zIndex: 9
                            }}
                            onMouseEnter={() => {
                                setHoveredInfo({category: category, text: text});
                            }}
                            onMouseLeave={() => {
                                if (!isPinned.current) {
                                    setHoveredInfo({category: '', text: ''});
                                }
                            }}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent bubbling up to parent
                                if (isPinned.current) {
                                    // Unpin if clicking the same rectangle
                                    isPinned.current = false;
                                    setHoveredInfo({category: '', text: ''});
                                } else {
                                    // Pin the hovered info
                                    isPinned.current = true;
                                    setHoveredInfo({category: category, text: text});
                                }
                            }}


                        >
                        </div>
                    );
                }
            }
        }
    }
    return renderedRectangles;
};

interface PdfViewerProps {
    bboxes: FloatListStringTuple[];
    fileBinary: File;
    setHoveredInfo: any
    isPinned: any
}


const PdfViewer: React.FC<PdfViewerProps> = ({bboxes, fileBinary, setHoveredInfo, isPinned}) => {
    const [viewerWidth, setViewerWidth] = useState(0);
    const [viewerHeight, setViewerHeight] = useState(0);
    const scale = 2;
    const [blobUrl, setBlobUrl] = useState<string | null>(null);

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState<number>(0);

    const nextPage = () => setPageNumber((prevPage) => prevPage + 1);
    const prevPage = () => setPageNumber((prevPage) => Math.max(prevPage - 1, 1));


    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages);
    }


    // Generate a Blob URL for the fileBinary
    useEffect(() => {
        if (fileBinary) {
            const url = URL.createObjectURL(fileBinary);
            setBlobUrl(url);

            // Clean up the Blob URL when component unmounts or fileBinary changes
            return () => URL.revokeObjectURL(url);
        }
    }, [fileBinary]);
    // Render PDF viewer when fileBinary is available
    return (

        <div className="pdf-viewer">
            <div className="controls">

                <button onClick={prevPage} disabled={pageNumber === 1}>Previous Page</button>
                <button onClick={nextPage} disabled={bboxes == null || numPages == pageNumber}>Next Page</button>
            </div>
            {fileBinary && <Document
                file={blobUrl}
                loading={""}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <div style={{position: 'relative'}}>
                    <Page
                        scale={scale}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        width={500}
                        onLoadSuccess={async (page) => {
                            console.log(page.height)
                            console.log(page.width)
                            setViewerHeight(page.height)
                            setViewerWidth(page.width)
                        }}
                    />

                    {viewerWidth && viewerHeight && renderRectangles(bboxes, pageNumber, viewerWidth, viewerHeight, isPinned, setHoveredInfo)}
                </div>
            </Document>}
        </div>
    );
}

